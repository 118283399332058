import React from "react";
import Seo from "../components/Seo";

const title = "Page not found";
const description = "The page you looked for does not exist.";

const NotFoundPage = () => (
  <div>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist...</p>
    <Seo manualTitle={title} manualDescription={description} />
  </div>
);

export default NotFoundPage;
